@import "./../../index.scss";

.customized-button{
    background-color: $marine-blue;
    color: $white;
    border-radius: 7px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease;
    &:hover{
        background-color: hsl(213, 73%, 31%);
    }
}