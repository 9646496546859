@import "./../../../index.scss";

.second-step-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap:40px;
   .select-plan-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        height: 100%;
        max-height: 170px;
    }
    .monthly-yearly-switcher-container{
        background-color: $alabaster;
        display: flex;
        padding: 15px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        gap:30px;
        h2{
            font-size: 16px;
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;
            input {
                display: none;
                &:checked + .slider:before {
                  transform: translateX(20px);
                } 
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $marine-blue;
                transition: 0.4s;
                border-radius: 34px;
                &:before {
                    position: absolute;
                    content: "";
                    height: 12px;
                    width: 12px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    transition: 0.4s;
                    border-radius: 50%;
                }
            }
        }       
    }
}

@media screen and (max-width:740px) {
    .select-plan-container{
        grid-template-columns: 1fr !important;
        row-gap: 20px;
        max-height: none !important;
    }
}

