@import "./../../../../index.scss";

.plan-card{
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    transition: 0.3s ease;
    .img-holder{
        display: flex;
        align-items: flex-start;
    }
    .description{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap:5px;
    }
    &:hover{
        border: 1px solid $marine-blue;
    }
    
}
.plan-card-focused{
        border: 1px solid $marine-blue;
        background-color: $alabaster; 
}

@media screen and (max-width:740px) {
    .plan-card{
        justify-content: normal !important;
        flex-direction: row !important;
        gap:20px;
        max-height: none !important;
    }
}
