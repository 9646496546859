/// Colors ///
/// Primary
$marine-blue:hsl(213, 96%, 18%);
$purplish-blue:hsl(243, 100%, 62%);
$pastel-blue:hsl(228, 100%, 84%);
$light-blue:hsl(206, 94%, 87%);
$strawberry-red:hsl(354, 84%, 57%);

///Neutral

$cool-gray:hsl(231, 11%, 63%);
$light-gray:hsl(229, 24%, 87%);
$magnolia:hsl(217, 100%, 97%);
$alabaster:hsl(231, 100%, 99%);
$white:hsl(0, 0%, 100%);


*{
    font-family: 'Ubuntu', sans-serif;
    margin: 0px;
    box-sizing: border-box;
    color: $marine-blue;
}

body{
    overflow-x: hidden;
}

p{
    font-size: 16px;
}

button,input,a{
    outline: none;
    border: none;
    cursor: pointer;
}

h1{
    font-size: 36px;
}

.grey-text{
    color:$cool-gray;
}

.App{
    width: 100vw;
    height: 100vh;
    background-color: $magnolia;
    display: flex;
    justify-content: center;
    align-items: center;
    section{
        max-width: 945px;
        max-height: 600px;
        height: 100%;
        width: 100%;
        background-color: $white;
        padding: 15px;
        border-radius: 12px;
        display: flex;
        margin: 20px;
        aside{
            max-width: 275px;
            width: 100%;
            display: flex;
            padding: 40px 30px;
            background-image: url("./../public/images/bg-sidebar-desktop.svg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            color: $white;
            border-radius: 15px;
            .circle-list {
                list-style-type: none;
                padding: 0px;
                width: 100%;
                display: flex;
                flex-direction: column;  
                gap:30px;
                li{
                    position: relative;
                    counter-increment: item;
                    padding-left: 3.5em;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    gap:5px;
                    &::before{
                        content: counter(item);
                        color: $alabaster;
                        width: 1.2em;
                        height: 1.2em;
                        border-radius: 50%;
                        text-align: center;
                        border: 1px solid $alabaster;
                        position: absolute;
                        padding: 7px;
                        left: 0;
                    }
                    h2{
                        font-size: 14px;
                        font-weight: 300;
                        color: $light-gray;
                    }
                    h1{
                        font-size: 14px;
                        color: $white;
                        letter-spacing: 1px;
                    }
                }
                .activated{
                    /* Add your custom styles here */
                &::before {
                        transition: 0.3s ease;
                        background-color: $light-blue;
                        border-color: $light-blue;
                        color: $marine-blue;
                    }
                }
            }
        }
        .steps-container-outer{
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:30px 0px;
            .steps-container-inner{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                max-width: 400px;
                gap:20px;
                .dad{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    gap:40px;
                    header{
                        display: flex;
                        flex-direction: column;
                        gap:10px;
                        p{
                            line-height: 1.3;
                        }
                    }  
                }
                .align-button-to-right{
                    display: flex;
                    justify-content: flex-end;
                }
                .align-buttons-between{
                    display: flex;
                    justify-content: space-between;
                }  
            }
        }
        .thank-you-container{
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .thank-you{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap:15px;
                text-align: center;
                max-width: 440px;
                width: 100%;
                p{
                    line-height: 1.4;
                }    
            }
        }
    }
}

@media screen and (max-width:740px) {
    section{
        max-width: 100vw !important;
        max-height: 100vh !important;
        margin: 0px !important;
        padding: 0px !important;
        background-color: transparent !important;
        aside {
            z-index: 1 !important;
            position: absolute;
            max-width: 100vw !important;
            max-height: 170px !important;
            height: 100%;
            border-radius: 0px !important;
            background-image: url("./../public/images/bg-sidebar-mobile.svg") !important;
            .circle-list{
                flex-direction: row !important;
                justify-content: center !important;
                li h1,h2{
                    display: none !important;
                } 
            }
        }
        .steps-container-outer{
            position: relative !important;
            z-index: 2;
            padding: 0px !important;
            // background-color: $white;
            .steps-container-inner{
                max-width: initial !important;
                width: 100% !important;
                align-items: center;
                .dad{
                    flex-grow: 0 !important;
                    max-width: 400px !important;
                    width: 100% !important;
                    background-color: $white;
                    padding: 30px 20px !important;
                    border-radius: 12px !important;
                    margin-top: 110px !important;
                    box-shadow: -2px 2px 15px 0px rgba(0,0,0,0.14);
                    -webkit-box-shadow: -2px 2px 15px 0px rgba(0,0,0,0.14);
                    -moz-box-shadow: -2px 2px 15px 0px rgba(0,0,0,0.14);
                } 
                .align-button-to-right, .align-buttons-between{
                    padding: 20px !important;
                    background-color: $white;
                    width: 100%;
                }
                .thank-you-container{
                    max-height: 400px !important;
                    height: 100% !important;
                    background-color: $white;
                    padding:20px !important;
                    border-radius: 10px !important;
                    margin-top: 110px !important;
                }
            } 
        }
    }
}